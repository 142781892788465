import { localFormat } from "./fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { englishUS } from "./fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { year, addDays, dayOfWeek, day as day_5, compare, date as date_1, equals } from "./fable_modules/fable-library.3.6.3/Date.js";
import { exists, empty as empty_2, ofSeq, contains } from "./fable_modules/fable-library.3.6.3/Set.js";
import { op_Multiply, op_Subtraction, fromParts, op_Division } from "./fable_modules/fable-library.3.6.3/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.3/Decimal.js";
import { replace, printf, interpolate, toText } from "./fable_modules/fable-library.3.6.3/String.js";
import { DOMAttr, HTMLAttr, CSSProp } from "./fable_modules/Fable.React.5.1.0/Fable.React.Props.fs.js";
import { last, item, empty, singleton } from "./fable_modules/fable-library.3.6.3/List.js";
import * as react from "react";
import { keyValueList } from "./fable_modules/fable-library.3.6.3/MapUtil.js";
import { collect, map as map_1, sort, chunkBySize, where, initializeInfinite, skip, takeWhile, empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.3/Seq.js";
import { isWeekDay, Or, dayStatus } from "./Utils.fs.js";
import { map } from "./fable_modules/fable-library.3.6.3/Option.js";
import { DayType__get_DisplayText, Msg } from "./Types.fs.js";
import { equals as equals_1, partialApply, compareArrays } from "./fable_modules/fable-library.3.6.3/Util.js";
import { PART_TIME_RATIO } from "./Constants.fs.js";
import { eventDates } from "./Model.fs.js";

export const dateFormat = (formatString) => ((date) => localFormat(englishUS, formatString, date));

export function eventDate(event) {
    const start = dateFormat("dddd, MMMM d")(event.StartDate);
    const end$0027 = dateFormat("dddd, MMMM d")(event.EndDate);
    if (equals(event.StartDate, event.EndDate)) {
        return start;
    }
    else {
        return `${start} — ${end$0027}`;
    }
}

export function dayCell(active, dispatch, highlightDates, today, date, day) {
    const isToday = equals(date_1(date), today) ? " today" : "";
    const highlight = contains(date, highlightDates) ? " highlight" : "";
    let partialStyle;
    let pattern_matching_result, hours;
    if (day != null) {
        if (day.DayType.tag === 2) {
            pattern_matching_result = 0;
            hours = day.DayType.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const percentage = op_Division(hours, fromParts(8, 0, 0, false, 0));
            partialStyle = singleton(new CSSProp(21, ("hsl(116, 67%" + toText(interpolate(", %.0f%P()", [op_Subtraction(fromParts(100, 0, 0, false, 0), op_Multiply(fromParts(50, 0, 0, false, 0), percentage))]))) + "%)"));
            break;
        }
        case 1: {
            partialStyle = empty();
            break;
        }
    }
    if (day != null) {
        const day_1 = day;
        return react.createElement("td", keyValueList(toList(delay(() => append(singleton_1(new HTMLAttr(24, `${day_1.DayType}${isToday}${highlight}`)), delay(() => append(singleton_1(["style", keyValueList(partialStyle, 1)]), delay(() => {
            let clo1;
            return append(singleton_1(new HTMLAttr(118, dayStatus(day_1.DayType) + Or()("")(map((clo1 = toText(printf(", %s")), (arg10) => clo1(arg10)), day_1.Description)))), delay(() => (active ? singleton_1(new DOMAttr(56, (_arg1_1) => {
                dispatch(new Msg(2, ofSeq([day_1.Date], {
                    Compare: (x, y) => compare(x, y),
                })));
            })) : empty_1())));
        })))))), 1), `${day_5(date)}`);
    }
    else {
        return react.createElement("td", {
            className: "filler-day",
        }, `${day_5(date)}`);
    }
}

export function calendar(dispatch, highlight, today, active, _arg1) {
    if (_arg1 != null) {
        const month = _arg1;
        const firstDay = item(0, month.Days).Date;
        const leadingDays = takeWhile((x_1) => (dayOfWeek(x_1) !== 0), skip(1, initializeInfinite((arg_1) => addDays(firstDay, -1 * arg_1))));
        const lastDay = last(month.Days).Date;
        const trailingDays = takeWhile((x_2) => (dayOfWeek(x_2) !== 1), skip(1, initializeInfinite((arg_2) => addDays(lastDay, arg_2))));
        const weeks = where((array) => array.some((arg_4) => (arg_4[1] != null)), chunkBySize(5, sort(where((arg_3) => isWeekDay(arg_3[0]), delay(() => append(map_1((day) => [day, void 0], leadingDays), delay(() => append(map_1((day_1) => [day_1.Date, day_1], month.Days), delay(() => map_1((day_2) => [day_2, void 0], trailingDays))))))), {
            Compare: (x_3, y_1) => compareArrays(x_3, y_1),
        })));
        return react.createElement("div", keyValueList(toList(delay(() => append(singleton_1(new HTMLAttr(24, "calendar-month")), delay(() => append(singleton_1(new DOMAttr(53, (_arg1_1) => {
            dispatch(new Msg(2, empty_2({
                Compare: (x_4, y_2) => compare(x_4, y_2),
            })));
        })), delay(() => ((!active) ? singleton_1(new DOMAttr(40, (_arg2) => {
            dispatch(new Msg(0, month.Date));
        })) : empty_1()))))))), 1), react.createElement("table", {}, react.createElement("thead", {}, react.createElement("tr", {}, ...toList(delay(() => map_1((c) => react.createElement("th", {}, `${c}`), "MTWTF".split("")))))), react.createElement("tbody", {}, ...toList(delay(() => map_1((week) => react.createElement("tr", {}, ...toList(delay(() => map_1((day_3) => {
            const tupledArg = day_3;
            return dayCell(active, dispatch, highlight, today, tupledArg[0], tupledArg[1]);
        }, week)))), weeks))))));
    }
    else {
        return react.createElement("div", {
            className: "calendar-empty",
        });
    }
}

export function view(state, dispatch) {
    document.onkeydown = ((e) => {
        const matchValue = [e.key, state.PreviousMonth, state.NextMonth];
        let pattern_matching_result, month;
        if (matchValue[0] === "ArrowLeft") {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                month = matchValue[1];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue[0] === "ArrowRight") {
            if (matchValue[2] != null) {
                pattern_matching_result = 0;
                month = matchValue[2];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue[0] === "Home") {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                dispatch(new Msg(0, month.Date));
                break;
            }
            case 1: {
                dispatch(new Msg(0, state.Today));
                break;
            }
            case 2: {
                break;
            }
        }
    });
    const monthName = localFormat(englishUS, "MMMM", state.Month.Date);
    const partTimeWorkingDays = replace(toText(interpolate("%.1f%P()", [op_Multiply(new Decimal(state.Month.WorkingDays), PART_TIME_RATIO)])), ".0", "");
    const calendar$0027 = (active, _arg1) => calendar(dispatch, state.HighlightDates, state.Today, active, _arg1);
    const activeCalendar = partialApply(1, calendar$0027, [true]);
    const passiveCalendar = partialApply(1, calendar$0027, [false]);
    return react.createElement("div", {
        className: "page-wrapper",
    }, react.createElement("div", {
        className: "status-section",
    }, react.createElement("h2", {}, "Is Petr working today?"), react.createElement("h1", {}, state.TodayStatus)), react.createElement("div", {
        className: "calendar-section",
    }, react.createElement("div", {
        className: "active-month",
    }, react.createElement("h3", {}, `${monthName} ${year(state.Month.Date)}`)), react.createElement("div", {
        className: "calendar previous",
    }, passiveCalendar(state.PreviousMonth)), react.createElement("div", {
        className: "calendar active",
    }, activeCalendar(state.Month)), react.createElement("div", {
        className: "calendar next",
    }, passiveCalendar(state.NextMonth))), react.createElement("div", {
        className: "details-section",
    }, ...toList(delay(() => append(singleton_1(react.createElement("div", {
        className: "stats",
    }, react.createElement("p", {}, react.createElement("span", {}, "Weekdays"), react.createElement("strong", {}, `${state.Month.Weekdays}`)), react.createElement("div", {
        className: "deductions",
    }, ...toList(delay(() => collect((matchValue_1) => singleton_1(react.createElement("p", {}, react.createElement("span", {}, `${DayType__get_DisplayText(matchValue_1[0])}`), react.createElement("strong", {}, `-${matchValue_1[1]}`))), state.Month.Deductions)))), react.createElement("p", {}, react.createElement("span", {}, "Working days"), react.createElement("strong", {}, `${state.Month.WorkingDays}`)), react.createElement("p", {
        className: "ratio",
    }, react.createElement("span", {}, toText(interpolate("× %.0f%P() ", [op_Multiply(PART_TIME_RATIO, fromParts(100, 0, 0, false, 0))])) + "%"), react.createElement("strong", {}, partTimeWorkingDays)))), delay(() => ((!equals_1(state.Month.Events, empty())) ? singleton_1(react.createElement("div", {
        className: "events",
        onMouseLeave: (_arg1_1) => {
            dispatch(new Msg(2, empty_2({
                Compare: (x, y) => compare(x, y),
            })));
        },
    }, ...toList(delay(() => collect((event) => {
        const highlight_1 = exists((date) => {
            if (compare(date, event.StartDate) >= 0) {
                return compare(date, event.EndDate) <= 0;
            }
            else {
                return false;
            }
        }, state.HighlightDates) ? " highlight" : "";
        return singleton_1(react.createElement("div", {
            className: `event ${event.EventType}${highlight_1}`,
            onMouseOver: (_arg2) => {
                dispatch(new Msg(2, ofSeq(eventDates(event), {
                    Compare: (x_1, y_1) => compare(x_1, y_1),
                })));
            },
        }, react.createElement("div", {
            className: "date",
        }, `${eventDate(event)}`), react.createElement("div", {
            className: "description",
        }, event.Description)));
    }, state.Month.Events))))) : empty_1())))))), react.createElement("div", {
        className: "footer",
    }, react.createElement("a", {
        href: "https://github.com/0101/ipwt",
    }, "source"), "|", react.createElement("a", {
        href: "https://github.com/0101/ipwt/issues",
    }, "report bug")));
}

