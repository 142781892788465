import { sortBy, append as append_1, cons, fold, sumBy, choose, empty, filter } from "./fable_modules/fable-library.3.6.3/List.js";
import { dayToEvent, getMonthDates, Or, isWeekDay } from "./Utils.fs.js";
import { empty as empty_1, singleton, collect, length, sortByDescending, skipWhile, tryHead, append, where, map, delay, toList } from "./fable_modules/fable-library.3.6.3/Seq.js";
import { today as today_1, addMonths, compare, month as month_1, year, op_Subtraction, date as date_1, addDays } from "./fable_modules/fable-library.3.6.3/Date.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.3/Range.js";
import { days as days_1 } from "./fable_modules/fable-library.3.6.3/TimeSpan.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.3/String.js";
import { State, Month, Day, Event$, DayType__get_DisplayText } from "./Types.fs.js";
import { ALLOCATION_POLICY, EVENTS } from "./Events.fs.js";
import { ofSeq as ofSeq_1, FSharpMap__TryFind } from "./fable_modules/fable-library.3.6.3/Map.js";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.3/Option.js";
import { BeginningOfMonth } from "./AllocationPolicy.fs.js";
import { op_Addition, op_Subtraction as op_Subtraction_1, fromParts, op_Multiply } from "./fable_modules/fable-library.3.6.3/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.3/Decimal.js";
import { FIRST_MONTH, PART_TIME_DAILY_HOURS, PART_TIME_RATIO, DAILY_HOURS } from "./Constants.fs.js";
import { List_countBy } from "./fable_modules/fable-library.3.6.3/Seq2.js";
import { comparePrimitives, safeHash, equals } from "./fable_modules/fable-library.3.6.3/Util.js";
import { empty as empty_2, ofSeq, remove } from "./fable_modules/fable-library.3.6.3/Set.js";
import { Fetch } from "./Holidays.fs.js";

export function eventDates(event) {
    return filter((d) => isWeekDay(d), toList(delay(() => {
        let copyOfStruct_1;
        return map((x) => {
            let copyOfStruct = addDays(event.StartDate, x);
            return date_1(copyOfStruct);
        }, toList(rangeDouble(0, 1, (copyOfStruct_1 = op_Subtraction(event.EndDate, event.StartDate), days_1(copyOfStruct_1)))));
    })));
}

export function getEventsFor(month, holidays) {
    let source2;
    return toList(where((e) => {
        if ((year(e.StartDate) === year(month)) && (month_1(e.StartDate) === month_1(month))) {
            return true;
        }
        else if (year(e.EndDate) === year(month)) {
            return month_1(e.EndDate) === month_1(month);
        }
        else {
            return false;
        }
    }, (source2 = map((tupledArg) => {
        const _arg1 = tupledArg[0];
        const type$0027 = tupledArg[1];
        const desc = tupledArg[2];
        return new Event$(_arg1[0], _arg1[1], isNullOrWhiteSpace(desc) ? DayType__get_DisplayText(type$0027) : desc, type$0027);
    }, EVENTS), append(Or()(empty())(FSharpMap__TryFind(holidays, year(month))), source2))));
}

export function getCurrentPolicy(date) {
    return defaultArg(tryHead(map((tuple_2) => tuple_2[1], skipWhile((arg) => (compare(arg[0], date) > 0), sortByDescending((tuple) => tuple[0], ALLOCATION_POLICY, {
        Compare: (x, y) => compare(x, y),
    })))), (remainingHours) => ((remainingDates) => BeginningOfMonth(remainingHours, remainingDates)));
}

export function initMonth(holidays, month) {
    const events = getEventsFor(month, holidays);
    const dates = getMonthDates(month);
    const weekdays = length(where((d) => isWeekDay(d), dates)) | 0;
    const partTimeHours = op_Multiply(op_Multiply(new Decimal(weekdays), DAILY_HOURS), PART_TIME_RATIO);
    const eventDates_1 = toList(delay(() => collect((event) => collect((date) => ((month_1(date) === month_1(month)) ? singleton([date, event]) : empty_1()), eventDates(event)), events)));
    const deductions = List_countBy((x) => x, choose((tupledArg) => {
        const e = tupledArg[1];
        const matchValue = e.EventType;
        switch (matchValue.tag) {
            case 3:
            case 4:
            case 5: {
                return e.EventType;
            }
            default: {
                return void 0;
            }
        }
    }, eventDates_1), {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    });
    const workingDays = (weekdays - sumBy((tuple) => tuple[1], deductions, {
        GetZero: () => 0,
        Add: (x_2, y_1) => (x_2 + y_1),
    })) | 0;
    const patternInput = fold((tupledArg_1, tupledArg_2) => {
        const eventDate = tupledArg_2[0];
        const event_1 = tupledArg_2[1];
        let hours_1;
        const matchValue_1 = event_1.EventType;
        switch (matchValue_1.tag) {
            case 3:
            case 4:
            case 5: {
                hours_1 = PART_TIME_DAILY_HOURS;
                break;
            }
            case 2: {
                hours_1 = matchValue_1.fields[0];
                break;
            }
            case 1: {
                hours_1 = fromParts(0, 0, 0, false, 0);
                break;
            }
            default: {
                hours_1 = DAILY_HOURS;
            }
        }
        const day = new Day(eventDate, event_1.EventType, event_1.Description);
        return [remove(eventDate, tupledArg_1[0]), op_Subtraction_1(tupledArg_1[1], hours_1), cons(day, tupledArg_1[2])];
    }, [ofSeq(dates, {
        Compare: (x_3, y_2) => compare(x_3, y_2),
    }), partTimeHours, empty()], eventDates_1);
    const regularDays = getCurrentPolicy(month)(patternInput[1])(patternInput[0]);
    const events_1 = append_1(events, choose((_arg1_1) => {
        if (_arg1_1.DayType.tag === 2) {
            return dayToEvent(_arg1_1);
        }
        else {
            return void 0;
        }
    }, regularDays));
    const allDays = append_1(patternInput[2], regularDays);
    const checksum = sumBy((_arg2) => {
        if (_arg2.DayType.tag === 0) {
            return DAILY_HOURS;
        }
        else if (_arg2.DayType.tag === 2) {
            return _arg2.DayType.fields[0];
        }
        else {
            return fromParts(0, 0, 0, false, 0);
        }
    }, allDays, {
        GetZero: () => (new Decimal(0)),
        Add: (x_4, y_3) => op_Addition(x_4, y_3),
    });
    return new Month(month, sortBy((d_3) => d_3.Date, allDays, {
        Compare: (x_5, y_4) => compare(x_5, y_4),
    }), sortBy((e_1) => e_1.StartDate, events_1, {
        Compare: (x_6, y_5) => compare(x_6, y_5),
    }), weekdays, deductions, workingDays);
}

export function previousMonth(date) {
    if (compare(addMonths(date, -1), FIRST_MONTH) < 0) {
        return void 0;
    }
    else {
        return addMonths(date, -1);
    }
}

export function nextMonth(date) {
    return addMonths(date, 1);
}

export function initPreviousMonth(holidays) {
    return (arg) => map_1((month) => initMonth(holidays, month), previousMonth(arg));
}

export function initNextMonth(holidays) {
    return (arg) => map_1((month) => initMonth(holidays, month), nextMonth(arg));
}

export function initState(today) {
    const holidays = ofSeq_1([], {
        Compare: (x, y) => comparePrimitives(x, y),
    });
    return new State(today, "", initMonth(holidays, today), initPreviousMonth(holidays)(today), initNextMonth(holidays)(today), empty_2({
        Compare: (x_1, y_1) => compare(x_1, y_1),
    }), holidays);
}

export function init() {
    const today = today_1();
    return [initState(today), Fetch(year(today))];
}

