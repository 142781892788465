import { FSharpMap__ContainsKey, FSharpMap__Add } from "./fable_modules/fable-library.3.6.3/Map.js";
import { Msg, State } from "./Types.fs.js";
import { Cmd_batch, Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { dayStatus } from "./Utils.fs.js";
import { ofArray, choose, find } from "./fable_modules/fable-library.3.6.3/List.js";
import { year as year_1, date as date_3, equals } from "./fable_modules/fable-library.3.6.3/Date.js";
import { nextMonth, previousMonth, initNextMonth, initPreviousMonth, initMonth } from "./Model.fs.js";
import { Fetch } from "./Holidays.fs.js";

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new State(state.Today, state.TodayStatus, state.Month, state.PreviousMonth, state.NextMonth, state.HighlightDates, FSharpMap__Add(state.Holidays, msg.fields[0], msg.fields[1])), Cmd_OfFunc_result(new Msg(0, state.Month.Date))];
        }
        case 2: {
            return [new State(state.Today, state.TodayStatus, state.Month, state.PreviousMonth, state.NextMonth, msg.fields[0], state.Holidays), Cmd_none()];
        }
        default: {
            const date = msg.fields[0];
            return [new State(state.Today, (state.TodayStatus === "") ? dayStatus(find((x) => equals(date_3(x.Date), date_3(state.Today)), initMonth(state.Holidays, state.Today).Days).DayType) : state.TodayStatus, initMonth(state.Holidays, date), initPreviousMonth(state.Holidays)(date), initNextMonth(state.Holidays)(date), state.HighlightDates, state.Holidays), Cmd_batch(choose((_arg1) => {
                let pattern_matching_result, date_2;
                if (_arg1 != null) {
                    if (!FSharpMap__ContainsKey(state.Holidays, year_1(_arg1))) {
                        pattern_matching_result = 0;
                        date_2 = _arg1;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return Fetch(year_1(date_2));
                    }
                    case 1: {
                        return void 0;
                    }
                }
            }, ofArray([date, previousMonth(date), nextMonth(date)])))];
        }
    }
}

