import { create } from "./fable_modules/fable-library.3.6.3/Date.js";
import { op_Multiply, fromParts } from "./fable_modules/fable-library.3.6.3/Decimal.js";

export const FIRST_MONTH = create(2021, 7, 1);

export const PART_TIME_RATIO = fromParts(6, 0, 0, false, 1);

export const DAILY_HOURS = fromParts(8, 0, 0, false, 0);

export const PART_TIME_DAILY_HOURS = op_Multiply(PART_TIME_RATIO, DAILY_HOURS);

