import { value as value_1, defaultArg } from "./fable_modules/fable-library.3.6.3/Option.js";
import { singleton, empty } from "./fable_modules/fable-library.3.6.3/Set.js";
import { compare } from "./fable_modules/fable-library.3.6.3/Util.js";
import { addDays, month as month_1, year as year_1, create, dayOfWeek } from "./fable_modules/fable-library.3.6.3/Date.js";
import { initializeInfinite, takeWhile, toList } from "./fable_modules/fable-library.3.6.3/Seq.js";
import { Event$, DayType__get_DisplayText, Day, DayType } from "./Types.fs.js";

export function Or() {
    return (value) => ((option) => defaultArg(option, value));
}

export function Set_ofOption(_arg1) {
    if (_arg1 == null) {
        return empty({
            Compare: (x_1, y_1) => compare(x_1, y_1),
        });
    }
    else {
        return singleton(value_1(_arg1), {
            Compare: (x, y) => compare(x, y),
        });
    }
}

export function isWeekDay(d) {
    if (dayOfWeek(d) !== 6) {
        return dayOfWeek(d) !== 0;
    }
    else {
        return false;
    }
}

export function isMondayTuesdayWednesday(date) {
    if ((dayOfWeek(date) === 1) ? true : (dayOfWeek(date) === 2)) {
        return true;
    }
    else {
        return dayOfWeek(date) === 3;
    }
}

export function getMonthDates(month) {
    const firstDay = create(year_1(month), month_1(month), 1);
    return toList(takeWhile((x) => (month_1(x) === month_1(firstDay)), initializeInfinite((arg) => addDays(firstDay, arg))));
}

export function dayOn(date) {
    return new Day(date, new DayType(0), void 0);
}

export function dayOff(date) {
    return new Day(date, new DayType(1), void 0);
}

export function partialDay(date, hours) {
    const dayType = new DayType(2, hours);
    return new Day(date, dayType, DayType__get_DisplayText(dayType));
}

export function dayToEvent(d) {
    return new Event$(d.Date, d.Date, Or()("")(d.Description), d.DayType);
}

export function dayStatus(_arg1) {
    switch (_arg1.tag) {
        case 0: {
            return "Yep";
        }
        case 2: {
            return "Kinda";
        }
        default: {
            return "Nope";
        }
    }
}

export function Events_on(year, month, day) {
    return [create(year, month, day), create(year, month, day)];
}

export function Events_from(year, month, day) {
    return create(year, month, day);
}

export const Events_to$0027 = (year) => ((month) => ((day) => Events_from(year, month, day)));

