import { append, singleton, sort, empty, cons, fold } from "./fable_modules/fable-library.3.6.3/List.js";
import { uncurry } from "./fable_modules/fable-library.3.6.3/Util.js";
import { isMondayTuesdayWednesday, dayOff, partialDay, dayOn, isWeekDay } from "./Utils.fs.js";
import { op_Subtraction, fromParts, compare } from "./fable_modules/fable-library.3.6.3/Decimal.js";
import { DAILY_HOURS } from "./Constants.fs.js";
import { ofSeq, difference, fold as fold_1, toList } from "./fable_modules/fable-library.3.6.3/Set.js";
import { compare as compare_1 } from "./fable_modules/fable-library.3.6.3/Date.js";
import { append as append_1, map, delay, toList as toList_1 } from "./fable_modules/fable-library.3.6.3/Seq.js";

export function BeginningOfMonth(remainingHours, remainingDates) {
    return fold(uncurry(2, (tupledArg) => {
        const remainingHours_1 = tupledArg[0];
        return (date) => {
            const patternInput = (isWeekDay(date) && (compare(remainingHours_1, DAILY_HOURS) >= 0)) ? [DAILY_HOURS, dayOn(date)] : ((isWeekDay(date) && (compare(remainingHours_1, fromParts(0, 0, 0, false, 0)) > 0)) ? [remainingHours_1, partialDay(date, remainingHours_1)] : [fromParts(0, 0, 0, false, 0), dayOff(date)]);
            return [op_Subtraction(remainingHours_1, patternInput[0]), cons(patternInput[1], tupledArg[1])];
        };
    }), [remainingHours, empty()], sort(toList(remainingDates), {
        Compare: (x, y) => compare_1(x, y),
    }))[1];
}

export function ThreeDaysAWeek(remainingHours, remainingDates) {
    const patternInput_1 = fold_1(uncurry(2, (tupledArg) => {
        const remainingHours_1 = tupledArg[0];
        return (date) => {
            const patternInput = (isMondayTuesdayWednesday(date) && (compare(remainingHours_1, DAILY_HOURS) >= 0)) ? [DAILY_HOURS, singleton(dayOn(date))] : ((isMondayTuesdayWednesday(date) && (compare(remainingHours_1, fromParts(0, 0, 0, false, 0)) > 0)) ? [remainingHours_1, singleton(partialDay(date, remainingHours_1))] : [fromParts(0, 0, 0, false, 0), empty()]);
            const day = patternInput[1];
            return [op_Subtraction(remainingHours_1, patternInput[0]), append(tupledArg[1], day), difference(tupledArg[2], ofSeq(toList_1(delay(() => map((d) => d.Date, day))), {
                Compare: (x, y) => compare_1(x, y),
            }))];
        };
    }), [remainingHours, empty(), remainingDates], remainingDates);
    return toList_1(delay(() => append_1(patternInput_1[1], delay(() => BeginningOfMonth(patternInput_1[0], patternInput_1[2])))));
}

