import { Events_to$0027, Events_from, Events_on } from "./Utils.fs.js";
import { DayType } from "./Types.fs.js";
import { fromParts } from "./fable_modules/fable-library.3.6.3/Decimal.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.6.3/List.js";
import { ThreeDaysAWeek } from "./AllocationPolicy.fs.js";

export const EVENTS = ofArray([[Events_on(2021, 7, 29), new DayType(0), ".NET Conf"], [[Events_from(2021, 8, 23), Events_to$0027(2021)(8)(27)], new DayType(0), "Azure Bootcamp"], [Events_on(2021, 8, 4), new DayType(1), ""], [Events_on(2021, 11, 30), new DayType(2, fromParts(48, 0, 0, false, 1)), ""], [[Events_from(2021, 12, 13), Events_to$0027(2021)(12)(23)], new DayType(4), ""], [Events_on(2022, 1, 13), new DayType(2, fromParts(48, 0, 0, false, 1)), ""], [Events_on(2022, 1, 18), new DayType(5), ""], [Events_on(2022, 1, 19), new DayType(5), ""], [Events_on(2022, 1, 26), new DayType(4), ""], [Events_on(2022, 1, 27), new DayType(4), ""], [Events_on(2022, 1, 31), new DayType(4), ""], [Events_on(2022, 4, 7), new DayType(4), ""], [[Events_from(2022, 4, 19), Events_to$0027(2022)(4)(21)], new DayType(4), ""], [[Events_from(2022, 4, 22), Events_to$0027(2022)(5)(10)], new DayType(1), "Trip to US"], [[Events_from(2022, 5, 11), Events_to$0027(2022)(5)(16)], new DayType(4), ""]]);

export const ALLOCATION_POLICY = singleton([Events_from(2022, 1, 1), (remainingHours) => ((remainingDates) => ThreeDaysAWeek(remainingHours, remainingDates))]);

