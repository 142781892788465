import { Record, toString, Union } from "./fable_modules/fable-library.3.6.3/Types.js";
import { tuple_type, int32_type, list_type, record_type, option_type, string_type, union_type, class_type } from "./fable_modules/fable-library.3.6.3/Reflection.js";
import { interpolate, toText } from "./fable_modules/fable-library.3.6.3/String.js";

export class DayType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DayOn", "DayOff", "PartialDay", "PublicHoliday", "Vacation", "SickDay"];
    }
}

export function DayType$reflection() {
    return union_type("App.Types.DayType", [], DayType, () => [[], [], [["Item", class_type("System.Decimal")]], [], [], []]);
}

export function DayType__get_DisplayText(this$) {
    const _arg1 = this$;
    switch (_arg1.tag) {
        case 3: {
            return "Public holiday";
        }
        case 5: {
            return "Sick day";
        }
        case 1: {
            return "Day off";
        }
        case 2: {
            return toText(interpolate("Partial day – %.1f%P() hours", [_arg1.fields[0]]));
        }
        default: {
            return toString(_arg1);
        }
    }
}

export class Day extends Record {
    constructor(Date$, DayType, Description) {
        super();
        this.Date = Date$;
        this.DayType = DayType;
        this.Description = Description;
    }
}

export function Day$reflection() {
    return record_type("App.Types.Day", [], Day, () => [["Date", class_type("System.DateTime")], ["DayType", DayType$reflection()], ["Description", option_type(string_type)]]);
}

export class Event$ extends Record {
    constructor(StartDate, EndDate, Description, EventType) {
        super();
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Description = Description;
        this.EventType = EventType;
    }
}

export function Event$$reflection() {
    return record_type("App.Types.Event", [], Event$, () => [["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Description", string_type], ["EventType", DayType$reflection()]]);
}

export class Month extends Record {
    constructor(Date$, Days, Events, Weekdays, Deductions, WorkingDays) {
        super();
        this.Date = Date$;
        this.Days = Days;
        this.Events = Events;
        this.Weekdays = (Weekdays | 0);
        this.Deductions = Deductions;
        this.WorkingDays = (WorkingDays | 0);
    }
}

export function Month$reflection() {
    return record_type("App.Types.Month", [], Month, () => [["Date", class_type("System.DateTime")], ["Days", list_type(Day$reflection())], ["Events", list_type(Event$$reflection())], ["Weekdays", int32_type], ["Deductions", list_type(tuple_type(DayType$reflection(), int32_type))], ["WorkingDays", int32_type]]);
}

export class State extends Record {
    constructor(Today, TodayStatus, Month, PreviousMonth, NextMonth, HighlightDates, Holidays) {
        super();
        this.Today = Today;
        this.TodayStatus = TodayStatus;
        this.Month = Month;
        this.PreviousMonth = PreviousMonth;
        this.NextMonth = NextMonth;
        this.HighlightDates = HighlightDates;
        this.Holidays = Holidays;
    }
}

export function State$reflection() {
    return record_type("App.Types.State", [], State, () => [["Today", class_type("System.DateTime")], ["TodayStatus", string_type], ["Month", Month$reflection()], ["PreviousMonth", option_type(Month$reflection())], ["NextMonth", option_type(Month$reflection())], ["HighlightDates", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.DateTime")])], ["Holidays", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, list_type(Event$$reflection())])]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchTo", "Holidays", "HighlightDates"];
    }
}

export function Msg$reflection() {
    return union_type("App.Types.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item1", int32_type], ["Item2", list_type(Event$$reflection())]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.DateTime")])]]]);
}

