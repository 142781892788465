import { singleton } from "./fable_modules/fable-library.3.6.3/AsyncBuilder.js";
import { awaitPromise } from "./fable_modules/fable-library.3.6.3/Async.js";
import { fetch$ } from "./fable_modules/Fable.Fetch.2.3.1/Fetch.fs.js";
import { empty } from "./fable_modules/fable-library.3.6.3/List.js";
import { empty as empty_1, singleton as singleton_1, collect, delay, toList } from "./fable_modules/fable-library.3.6.3/Seq.js";
import { date as date_1, parse } from "./fable_modules/fable-library.3.6.3/Date.js";
import { isWeekDay } from "./Utils.fs.js";
import { Msg, Event$, DayType } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export function holidayFile(year) {
    return `holidays/holidays-${year}.json`;
}

export function fetchHolidayData(year) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(fetch$(`/${holidayFile(year)}`, empty())), (_arg1) => singleton.ReturnFrom(awaitPromise(_arg1.text()))));
}

export function parseHolidayData(text) {
    return toList(delay(() => collect((event) => {
        let date;
        let copyOfStruct = parse(event["date"]);
        date = date_1(copyOfStruct);
        return isWeekDay(date) ? singleton_1(new Event$(date, date, event["name"], new DayType(3))) : empty_1();
    }, JSON.parse(text))));
}

export function fetchHolidays(year) {
    return singleton.Delay(() => singleton.Bind(fetchHolidayData(year), (_arg1) => singleton.Return([year, parseHolidayData(_arg1)])));
}

export function Fetch(year) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (year_1) => fetchHolidays(year_1), year, (tupledArg) => (new Msg(1, tupledArg[0], tupledArg[1])));
}

